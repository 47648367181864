<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" class="mt-2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col md="4" class="mt-5">
              <date-period
                :dense="true"
                :dateType="dateToggle"
                @periodChange="dateChange"
                :date1="date1"
                :date2="date2"
                class-name="q-text-field shadow-0"
                :hide-details="true"
              ></date-period>
            </v-col>
            <v-col md="6" class="">
              <v-btn-toggle
                borderless
                class="q-tabs mt-2"
                v-model="dateToggle"
                :mandatory="mandatory"
              >
                <v-btn :height="40" value="DATE" style="height: 40px !important"
                  >Day</v-btn
                >
                <v-btn
                  :height="40"
                  value="MONTH"
                  style="height: 40px !important"
                  >Month</v-btn
                >
                <v-btn :height="40" value="YEAR" style="height: 40px !important"
                  >Year</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col>Consolidated Visitation Analysis Report </v-col>
                  <v-spacer></v-spacer>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission($modules.reports.service.slug)
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <!-- <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item> -->
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content rounded-5 shadow-0">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="md-content md-table-content md-scrollbar md-theme-default"
                    v-if="visitationCountReport.length != 0"
                  >
                    <table class="logTable">
                      <thead class="md-card-header shadow-0">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label"></div>
                            </div>
                          </th>
                          <th
                            class="md-table-head"
                            :key="`data_${lndex}`"
                            v-for="(data, lndex) in visitationCountReport[
                              'date'
                            ]"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                {{ data }}
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <template>
                          <tr class="md-table-row head_bg">
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                BOOKINGS
                              </div>
                            </td>
                            <td
                              :key="`data_${lndex}`"
                              class="md-table-cell font-weight-black"
                              v-for="(booking, lndex) in visitationCountReport[
                                'bookings'
                              ]"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ formatNumber(booking) }}
                              </div>
                            </td>
                          </tr>
                          <tr class="md-table-row">
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                Scanned Visitors
                              </div>
                            </td>
                            <td
                              :key="`data_${lndex}`"
                              class="md-table-cell font-weight-black"
                              v-for="(
                                scanned_booking, lndex
                              ) in visitationCountReport['scanned_bookings']"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ formatNumber(scanned_booking) }}
                              </div>
                            </td>
                          </tr>

                          <tr class="md-table-row">
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              <div class="md-table-cell-container ml-5">
                                QR Scanner
                              </div>
                            </td>
                            <td
                              :key="`data_${lndex}`"
                              class="md-table-cell font-weight-black"
                              v-for="(
                                qr_scanned_booking, lndex
                              ) in visitationCountReport['scanned_qr_bookings']"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ formatNumber(qr_scanned_booking) }}
                              </div>
                            </td>
                          </tr>
                          <tr class="md-table-row">
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              <div class="md-table-cell-container ml-5">
                                Quick Scan
                              </div>
                            </td>
                            <td
                              :key="`data_${lndex}`"
                              class="md-table-cell font-weight-black"
                              v-for="(
                                quick_scanned_booking, lndex
                              ) in visitationCountReport[
                                'quick_scanned_bookings'
                              ]"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ formatNumber(quick_scanned_booking) }}
                              </div>
                            </td>
                          </tr>
                          <template
                            v-for="(
                              quickScanTagCat, lndexQuickScanTagCat
                            ) in visitationCountReport['quick_scan_tags']"
                          >
                            <tr
                              class="md-table-row"
                              :key="`data_${lndexQuickScanTagCat}`"
                            >
                              <td
                                class="md-table-cell-container font-weight-black"
                              >
                                <div class="md-table-cell-container ml-12">
                                  {{
                                    lndexQuickScanTagCat == ""
                                      ? "Unknown Category"
                                      : lndexQuickScanTagCat
                                  }}
                                </div>
                              </td>
                              <template
                                v-for="(
                                  catTotal, indexCatTotal
                                ) in quickScanTagCat['total']"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`data_${lndexQuickScanTagCat}_${indexCatTotal}`"
                                >
                                  <div
                                    class="md-table-cell-container ml-2 text-center"
                                  >
                                    {{ formatNumber(catTotal) }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                            <template
                              v-for="(
                                quickScanTag, lndexQuickScanTag
                              ) in quickScanTagCat"
                            >
                              <tr
                                class="md-table-row"
                                :key="`data_${lndexQuickScanTag}`"
                                v-if="lndexQuickScanTag != 'total'"
                              >
                                <td class="md-table-cell-container">
                                  <div class="md-table-cell-container ml-15">
                                    {{ lndexQuickScanTag }}
                                  </div>
                                </td>
                                <template
                                  v-for="(tag, indexTag) in quickScanTag"
                                >
                                  <td
                                    class="md-table-cell-container"
                                    :key="`data_${lndexQuickScanTag}_${indexTag}`"
                                  >
                                    <div
                                      class="md-table-cell-container ml-2 text-center"
                                    >
                                      {{ formatNumber(tag) }}
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </template>
                          </template>
                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['date']"
                          >
                            <td
                              :colspan="
                                visitationCountReport['date'].length + 1
                              "
                              class="md-table-cell-container font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                B2C BOOKINGS
                              </div>
                            </td>
                          </tr>
                          <template
                            v-for="(b2c, lndexB2c) in visitationCountReport[
                              'b2c'
                            ]"
                          >
                            <tr
                              class="md-table-row sub_section_head_bg"
                              :key="`b2c_data_${lndexB2c}`"
                            >
                              <td
                                class="md-table-cell-container font-weight-black"
                              >
                                <div class="md-table-cell-container">
                                  {{ lndexB2c + " Bookings" }}
                                  <!--  Product types -->
                                </div>
                              </td>
                              <template
                                v-for="(b2cTotal, lndexB2CTotal) in b2c[
                                  'total'
                                ]"
                              >
                                <td
                                  class="md-table-cell-container font-weight-black"
                                  :key="`b2c_type_total_${lndexB2CTotal}`"
                                >
                                  <div
                                    class="md-table-cell-container text-center"
                                  >
                                    {{ formatNumber(b2cTotal) }}
                                  </div>
                                </td>
                              </template>
                            </tr>

                            <template
                              v-for="(
                                b2cServices, lndexB2cServices
                              ) in visitationCountReport['b2c'][lndexB2c]"
                            >
                              <tr
                                class="md-table-row"
                                :key="`b2c_type_data_${lndexB2cServices}_${lndexB2c}`"
                                v-if="
                                  visitationCountReport['b2c'][lndexB2c][
                                    lndexB2cServices
                                  ]['total'] &&
                                  visitationCountReport['b2c'][lndexB2c][
                                    lndexB2cServices
                                  ]['total'].length > 0
                                "
                              >
                                <td
                                  class="md-table-cell-container font-weight-black"
                                >
                                  <div class="md-table-cell-container ml-5">
                                    {{ lndexB2cServices }}
                                    <!--  Services  -->
                                  </div>
                                </td>
                                <template
                                  v-for="(
                                    b2cServiceTotal, lndexB2CServiceTotal
                                  ) in b2cServices['total']"
                                >
                                  <td
                                    class="md-table-cell-container font-weight-black"
                                    :key="`b2c_service_total${lndexB2CServiceTotal}_${lndexB2cServices}_${lndexB2c}`"
                                  >
                                    <div
                                      class="md-table-cell-container text-center"
                                    >
                                      {{ formatNumber(b2cServiceTotal) }}
                                    </div>
                                  </td>
                                </template>
                              </tr>

                              <template
                                v-for="(
                                  b2cBookings, lndexB2cBookings
                                ) in b2cServices"
                              >
                                <tr
                                  class="md-table-row"
                                  :key="`b2c_service_data_${lndexB2cBookings}_${lndexB2cServices}_${lndexB2c}`"
                                  v-if="
                                    visitationCountReport['b2c'][lndexB2c][
                                      lndexB2cServices
                                    ]['total'] &&
                                    visitationCountReport['b2c'][lndexB2c][
                                      lndexB2cServices
                                    ]['total'].length > 0 &&
                                    lndexB2cBookings != 'total'
                                  "
                                >
                                  <td class="md-table-cell-container">
                                    <div class="md-table-cell-container ml-5">
                                      {{ lndexB2cBookings }}
                                    </div>
                                  </td>
                                  <template v-if="b2cBookings.length">
                                    <td
                                      class="md-table-cell-container"
                                      :key="`b2c_service_bookings_${indexB2cBookings}_${lndexB2cBookings}_${lndexB2cServices}_${lndexB2c}`"
                                      v-for="(
                                        b2cBookingsValue, indexB2cBookings
                                      ) in b2cBookings"
                                    >
                                      <div
                                        class="md-table-cell-container text-center"
                                      >
                                        {{
                                          formatNumber(
                                            b2cBookingsValue
                                              ? b2cBookingsValue
                                              : 0.0
                                          )
                                        }}
                                      </div>
                                    </td>
                                  </template>
                                </tr>
                              </template>
                            </template>
                          </template>

                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['b2b']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                B2B BOOKINGS
                              </div>
                            </td>
                            <template v-if="visitationCountReport['b2b']">
                              <td
                                class="md-table-cell-container font-weight-black"
                                :key="`b2b_total_${b2bProductsTotalIndex}_${b2bProductsTotalIndex}`"
                                v-for="(
                                  b2bProductsTotal, b2bProductsTotalIndex
                                ) in visitationCountReport['b2b']['total']"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    formatNumber(
                                      b2bProductsTotal ? b2bProductsTotal : 0.0
                                    )
                                  }}
                                </div>
                              </td>
                            </template>
                          </tr>
                          <template
                            v-for="(b2b, lndexB2b) in visitationCountReport[
                              'b2b'
                            ]"
                          >
                            <tr
                              class="md-table-row"
                              :key="`b2b_data_${lndexB2b}`"
                              v-if="lndexB2b != 'total'"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container ml-5">
                                  {{ lndexB2b.toString().split("_")[0] }}
                                </div>
                              </td>
                              <template
                                v-for="(b2bProducts, b2bProductsIndex) in b2b"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`b2b_product_data_${b2bProductsIndex}_${lndexB2b}`"
                                >
                                  <div
                                    class="md-table-cell-container text-center"
                                  >
                                    {{
                                      formatNumber(
                                        b2bProducts ? b2bProducts : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>
                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['b2e']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                B2E BOOKINGS
                              </div>
                            </td>
                            <template v-if="visitationCountReport['b2e']">
                              <td
                                class="md-table-cell-container font-weight-black"
                                :key="`b2e_total_${b2eProductsTotalIndex}_${b2eProductsTotalIndex}`"
                                v-for="(
                                  b2eProductsTotal, b2eProductsTotalIndex
                                ) in visitationCountReport['b2e']['total']"
                              >
                                <div class="md-table-cell-container">
                                  {{
                                    formatNumber(
                                      b2eProductsTotal ? b2eProductsTotal : 0.0
                                    )
                                  }}
                                </div>
                              </td>
                            </template>
                          </tr>
                          <template
                            v-for="(b2e, lndexB2e) in visitationCountReport[
                              'b2e'
                            ]"
                          >
                            <tr
                              class="md-table-row"
                              :key="`b2e_product_${lndexB2e}`"
                              v-if="lndexB2e != 'total'"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container ml-5">
                                  {{ lndexB2e.toString().split("_")[0] }}
                                </div>
                              </td>
                              <template
                                v-for="(b2eProducts, b2eProductsIndex) in b2e"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`b2e_product_data_${b2eProductsIndex}_${lndexB2e}`"
                                >
                                  <div class="md-table-cell-container">
                                    {{
                                      formatNumber(
                                        b2eProducts ? b2eProducts : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>

                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['b2g']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                B2G BOOKINGS
                              </div>
                            </td>
                            <template v-if="visitationCountReport['b2g']">
                              <td
                                class="md-table-cell-container font-weight-black"
                                :key="`b2g_data_${b2gProductsTotalIndex}_${b2gProductsTotalIndex}`"
                                v-for="(
                                  b2gProductsTotal, b2gProductsTotalIndex
                                ) in visitationCountReport['b2g']['total']"
                              >
                                <div class="md-table-cell-container">
                                  {{
                                    formatNumber(
                                      b2gProductsTotal ? b2gProductsTotal : 0.0
                                    )
                                  }}
                                </div>
                              </td>
                            </template>
                          </tr>
                          <template
                            v-for="(b2g, lndexB2g) in visitationCountReport[
                              'b2g'
                            ]"
                          >
                            <tr
                              class="md-table-row"
                              :key="`b2g_product_${lndexB2g}`"
                              v-if="lndexB2g != 'total'"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container ml-5">
                                  {{ lndexB2g.toString().split("_")[0] }}
                                </div>
                              </td>
                              <template
                                v-for="(b2gProducts, b2gProductsIndex) in b2g"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`b2g_product_data_${b2gProductsIndex}_${lndexB2g}`"
                                >
                                  <div class="md-table-cell-container">
                                    {{
                                      formatNumber(
                                        b2gProducts ? b2gProducts : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>

                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['date']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                QUICK SCAN
                              </div>
                            </td>
                            <td
                              :key="`data_${quickScan}`"
                              class="md-table-cell font-weight-black"
                              v-for="(
                                quick_scanned_booking, quickScan
                              ) in visitationCountReport[
                                'quick_scanned_bookings'
                              ]"
                            >
                              <div
                                class="md-table-cell-container text-center mr-2"
                              >
                                {{ formatNumber(quick_scanned_booking) }}
                              </div>
                            </td>
                          </tr>
                          <template>
                            <tr
                              class="md-table-row"
                              :key="`data_${lndexQuickScan}`"
                              v-for="(
                                quickScan, lndexQuickScan
                              ) in visitationCountReport['quick_scan']"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container ml-5">
                                  {{ lndexQuickScan }}
                                </div>
                              </td>
                              <template
                                v-for="(
                                  quickScanData, quickScanIndex
                                ) in quickScan"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`data_${quickScanIndex}_${lndexQuickScan}`"
                                >
                                  <div
                                    class="md-table-cell-container text-center"
                                  >
                                    {{
                                      formatNumber(
                                        quickScanData
                                          ? formatNumber(quickScanData)
                                          : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>
                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['date']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                B2C SCAN
                              </div>
                            </td>
                            <td
                              :key="`b2cdata_${b2cScan}_cusType`"
                              class="md-table-cell font-weight-black"
                              v-for="(
                                b2c_scanned_booking, b2cScan
                              ) in visitationCountReport[
                                'b2c_scanned_bookings'
                              ]"
                            >
                              <div
                                class="md-table-cell-container text-center mr-2"
                              >
                                {{ formatNumber(b2c_scanned_booking) }}
                              </div>
                            </td>
                          </tr>
                          <template>
                            <tr
                              class="md-table-row"
                              :key="`b2cdata_${lndexB2CScan}_cusTypeVal`"
                              v-for="(
                                b2cScan, lndexB2CScan
                              ) in visitationCountReport['b2c_scan']"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container ml-5">
                                  {{ lndexB2CScan }}
                                </div>
                              </td>
                              <template
                                v-for="(b2cScanData, b2cScanIndex) in b2cScan"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`data_${b2cScanIndex}_${lndexB2CScan}`"
                                >
                                  <div
                                    class="md-table-cell-container text-center"
                                  >
                                    {{
                                      formatNumber(
                                        b2cScanData
                                          ? formatNumber(b2cScanData)
                                          : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>

                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['date']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                            >
                              <div class="md-table-cell-container">
                                ACADEMY CHECK IN
                              </div>
                            </td>
                            <td
                              :key="`b2cdata_${b2cScanAcademy}`"
                              class="md-table-cell font-weight-black"
                              v-for="(
                                b2c_scanned_booking_academy, b2cScanAcademy
                              ) in visitationCountReport[
                                'b2c_scanned_bookings_academy'
                              ]"
                            >
                              <div
                                class="md-table-cell-container text-center mr-2"
                              >
                                {{ formatNumber(b2c_scanned_booking_academy) }}
                              </div>
                            </td>
                          </tr>
                          <template>
                            <tr
                              class="md-table-row"
                              :key="`b2cdata_${lndexB2CScanAcademy}`"
                              v-for="(
                                b2cScanAcademy, lndexB2CScanAcademy
                              ) in visitationCountReport['b2c_scan_academy']"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container ml-5">
                                  {{ lndexB2CScanAcademy }}
                                </div>
                              </td>
                              <template
                                v-for="(
                                  b2cScanDataAcademy, b2cScanIndexAcademy
                                ) in b2cScanAcademy"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`data_${b2cScanIndexAcademy}_${lndexB2CScanAcademy}`"
                                >
                                  <div
                                    class="md-table-cell-container text-center"
                                  >
                                    {{
                                      formatNumber(
                                        b2cScanDataAcademy
                                          ? formatNumber(b2cScanDataAcademy)
                                          : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>
                          <tr
                            class="md-table-row head_bg"
                            v-if="visitationCountReport['date']"
                          >
                            <td
                              class="md-table-cell-container text-center font-weight-black"
                              :colspan="
                                visitationCountReport['date'].length + 1
                              "
                            >
                              <div class="md-table-cell-container">
                                Hourly Analysis
                              </div>
                            </td>
                          </tr>
                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['date']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                              :colspan="
                                visitationCountReport['date'].length + 1
                              "
                            >
                              <div class="md-table-cell-container">
                                B2C Scan
                              </div>
                            </td>
                          </tr>
                          <template>
                            <tr
                              class="md-table-row"
                              :key="`data_${lndexHourly}`"
                              v-for="(
                                hourlyBookingsData, lndexHourly
                              ) in visitationCountReport['hourly_bookings']"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container">
                                  {{ lndexHourly }}
                                </div>
                              </td>
                              <template
                                v-for="(
                                  hourlyBookings, indexHour
                                ) in hourlyBookingsData"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`data_${indexHour}_${lndexHourly}`"
                                >
                                  <div
                                    class="md-table-cell-container text-center"
                                  >
                                    {{
                                      formatNumber(
                                        hourlyBookings
                                          ? formatNumber(hourlyBookings)
                                          : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>
                          <tr
                            class="md-table-row section_head_bg"
                            v-if="visitationCountReport['date']"
                          >
                            <td
                              class="md-table-cell-container font-weight-black"
                              :colspan="
                                visitationCountReport['date'].length + 1
                              "
                            >
                              <div class="md-table-cell-container">
                                Quick Scan
                              </div>
                            </td>
                          </tr>
                          <template>
                            <tr
                              class="md-table-row"
                              :key="`data_quick_scan_${lndexQuickScanHourly}`"
                              v-for="(
                                hourlyQuickScanBookingsData,
                                lndexQuickScanHourly
                              ) in visitationCountReport[
                                'quick_scan_hourly_bookings'
                              ]"
                            >
                              <td class="md-table-cell-container">
                                <div class="md-table-cell-container">
                                  {{ lndexQuickScanHourly }}
                                </div>
                              </td>
                              <template
                                v-for="(
                                  hourlyQuickScanBookings, indexQuickScanHour
                                ) in hourlyQuickScanBookingsData"
                              >
                                <td
                                  class="md-table-cell-container"
                                  :key="`data_quick_scan_${indexQuickScanHour}_${lndexQuickScanHourly}`"
                                >
                                  <div class="md-table-cell-container">
                                    {{
                                      formatNumber(
                                        hourlyQuickScanBookings
                                          ? formatNumber(
                                              hourlyQuickScanBookings
                                            )
                                          : 0.0
                                      )
                                    }}
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </template>
                          <tr class="md-table-row"></tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      dateToggle: "DATE",
      visitationCountReport: [],
      bookingIndexes: [],
      B2CBookingIndexes: [],
      b2cBookings: [],
      services: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        productTypeId: 4,
        timestamp: [],
      },
      date1: moment().subtract(0, "days").format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      visitationData: {
        bookings: [],
        scanned_bookings: [],
        scanned_qr_bookings: [],
        quick_scanned_bookings: [],
      },
      visitationDataB2C: {
        facility_bookings: [],
        academy_bookings: [],
      },
      totalPages: 0,
      page: 1,
      paginatedData: [],
      mandatory: false,
      hoursArray: [],
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {},
    },
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    dateLength() {
      return this.visitationCountReport.length;
    },
    rowSpan() {
      return parseInt(this.visitationCountReport.length / 4);
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data.filter((item) =>
        ["Academy", "Trainer"].includes(item.name)
      );
    },
  },
  mounted() {
    this.$store.dispatch("loadVenueServices").then(() => {
      this.searchParams.venueServiceId = this.venueServices[0]
        ? this.venueServices[0]
        : null;
      this.$forceUpdate();
    });

    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes").then((res) => {
        if (res.status == 200) {
          this.searchParams.productTypeId = this.productTypes[0]
            ? this.productTypes[0].id
            : null;
        }
      });
    } else {
      this.searchParams.productTypeId = this.productTypes[0]
        ? this.productTypes[0].id
        : null;
    }
    // this.getReport();
    this.setHoursArray();
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
      // this.visitationCountReport = [];
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/visitation-count-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            this.visitationCountReport = data.data;
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    reportDownload(type) {
      this.showLoader("Loading");
      let link = "";
      if (type == "pdf") {
        link = "visitation-count-report-pdf/download";
      } else if (type == "excel") {
        link = "visitation-count-report-excel/download";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.$http
        .get(`venues/reports/` + link + `${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.date1, "YYYY-MM-DD").isAfter(
          moment(this.date2, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?date_type=${this.dateToggle.toLowerCase()}&from_date=${
        this.date1
      }&to_date=${this.date2}`;
      //  if (this.venueServiceIds.length > 0) {
      //     this.venueServiceIds.map((service, index) => {
      //       url += `&venue_service_id[${index}]=${service}`;
      //     });
      //   }
      let venueService = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }
      if (venueService !== null) this.fileName += "-" + venueService.name;
      this.fileName = this.date1 + "-to-" + this.date2;
      if (this.fileName != null)
        this.fileName = "Visitation-Analysis-Report-" + this.fileName;
      else this.fileName = "Visitation-Analysis-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(0);
    },

    dateChange(data) {
      this.date1 = data.date1;
      this.date2 = data.date2;
      this.getReport();
    },
    setHoursArray() {
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 1; j++) {
          this.hoursArray.push(`${i}:${j === 0 ? `00` : 60 * j}`);
        }
      }
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}

.section_head_bg {
  background: #e1fcfc;
}
.sub_section_head_bg {
  background: #edf9ff;
}

.md-card-header {
  background-color: #edf7f8;
}
</style>
